import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportLayout } from '../reports/reports.types';
import { ReportLayoutQueryParams, ReportLayoutChart, ReportLayoutRelatedToType } from './reporting.types';
import { PaginatedResource } from '../../shared/models/paginated-resource';
import { BaseService } from '@services/base.service';
import { reportBuilderListMenuItems } from './reporting.constants';

@Injectable({ providedIn: 'root' })
export class ReportingService extends BaseService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  create<T>(model: Partial<ReportLayout<T>>): Observable<ReportLayout<T>> {
    return this.httpClient
      .post<{ report_layout: ReportLayout<T> }>(`report_layouts`, {
        report_layout: this.transformAttributeProp(model),
      })
      .pipe(map(({ report_layout }) => report_layout));
  }

  update<T>(model: Partial<ReportLayout<T>>): Observable<ReportLayout<T>> {
    return this.httpClient
      .patch<{ report_layout: ReportLayout<T> }>(`report_layouts/${model.id}`, {
        report_layout: this.transformAttributeProp(model),
      })
      .pipe(map(({ report_layout }) => report_layout));
  }

  getReports<T>(filter: ReportLayoutQueryParams): Observable<PaginatedResource<ReportLayout<T>>> {
    let params = new HttpParams();

    Object.keys(filter).forEach((key) => {
      params = params.append(key, filter[key]);
    });

    return this.httpClient.get<PaginatedResource<ReportLayout<T>>>(`report_layouts`, { params });
  }

  getReportById<T>(id: number, accountId: number): Observable<ReportLayout<T>> {
    return this.httpClient
      .get<{ report_layout: ReportLayout<T> }>(`report_layouts/${id}`)
      .pipe(map(({ report_layout }) => report_layout));
  }

  buildChartData(id: number, params: Partial<ReportLayoutChart>[], refresh = false): Observable<ReportLayoutChart[][]> {
    const url = `report_layouts/${id}/build_chart_data${refresh ? `?refresh=true` : ''}`;
    return this.httpClient
      .post<{ chart_data: any }>(url, { charts: [params] })
      .pipe(map((response) => response?.chart_data ?? [[]]));
  }

  delete<T>(id: number, accountId: number): Observable<ReportLayout<T>> {
    return this.httpClient.delete<any>(`report_layouts/${id}`).pipe(map(({ report_layout }) => report_layout));
  }

  getReportLayoutRelatedToTypesByAccount(accountId: string): Observable<ReportLayoutRelatedToType[]> {
    return this.httpClient
      .get<{ related_to_types: ReportLayoutRelatedToType[] }>(`report_layouts/related_to_types`)
      .pipe(map(({ related_to_types }) => related_to_types));
  }

  updateReportBuilderOptionsBadges(model: {
    allReportsCount: number;
    favoritesCount: number;
    byMeCount: number;
  }): void {
    const stgMenuItems = [...reportBuilderListMenuItems.value];

    const allReports = stgMenuItems.find((option) => option.id === 'all-reports');
    if (allReports) {
      allReports.badge.value = model.allReportsCount.toString();
    }

    const byMe = stgMenuItems.find((option) => option.id === 'created_by_me');
    if (byMe) {
      byMe.badge.value = model.byMeCount.toString();
    }

    const favorites = stgMenuItems.find((option) => option.id === 'favorites');
    if (favorites) {
      favorites.badge.value = model.favoritesCount.toString();
    }

    reportBuilderListMenuItems.next(stgMenuItems);
  }

  private transformAttributeProp(model: Partial<ReportLayout<any>>): Partial<ReportLayout<any>> {
    const value = { ...model };

    if (Object.keys(model).includes('report_layout_summary_formulas')) {
      value['report_layout_summary_formulas_attributes'] = [...value.report_layout_summary_formulas];
      delete value.report_layout_summary_formulas;
    }

    return value;
  }
}
