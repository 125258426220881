import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  TemplateRef,
  ElementRef,
  ViewContainerRef,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormFieldDirective } from './form-field.directive';
import { FormFieldInterfaceComponent, PartialFormField } from './form-field.types';
import { TagsFormFieldComponent } from './tags-form-field/tags-form-field.component';
import { TextFormFieldComponent } from './text-form-field/text-form-field.component';
import { TypeaheadFormFieldComponent } from './typeahead-form-field/typeahead-form-field.component';
import { TextAreaFormFieldComponent } from './textarea-form-field/textarea-form-field.component';
import { SelectFormFieldComponent } from './select-form-field/select-form-field.component';
import { MoneyFormFieldComponent } from './money-form-field/money-form-field.component';
import { NumberFormFieldComponent } from './number-form-field/number-form-field.component';
import { DateFormFieldComponent } from './date-form-field/date-form-field.component';
import { DateTimeFormFieldComponent } from './datetime-form-field/datetime-form-field.component';
import { PercentFormFieldComponent } from './percent-form-field/percent-form-field.component';
import { DecimalFormFieldComponent } from './decimal-form-field/decimal-form-field.component';
import { YearFormFieldComponent } from './year-form-field/year-form-field.component';
import { CheckboxFormFieldComponent } from './checkbox-form-field/checkbox-form-field.component';
import { UrlFormFieldComponent } from './url-form-field/url-form-field.component';
import isFunction from 'lodash-es/isFunction';
import { ContactFormFieldComponent } from './contact-form-field/contact-form-field.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { between } from 'app/shared/Utils/common.utils';
import { FieldDef, FieldDefType } from 'app/core/models';
import { FieldMaskUtils } from '../../Utils/field-mask.utils';
import { noop } from 'lodash';
import { UserState } from '@state/user/state';
import { Store } from '@ngxs/store';
import { ReservedRole } from '@core/enums';

const formFieldComponents: { [key in Exclude<FieldDefType, 'id'>]: any } = {
  text: TextFormFieldComponent,
  checkbox: CheckboxFormFieldComponent,
  select: SelectFormFieldComponent,
  tags: TagsFormFieldComponent,
  textarea: TextAreaFormFieldComponent,
  typeahead: TypeaheadFormFieldComponent,
  money: MoneyFormFieldComponent,
  number: NumberFormFieldComponent,
  date: DateFormFieldComponent,
  datetime: DateTimeFormFieldComponent,
  percent: PercentFormFieldComponent,
  decimal: DecimalFormFieldComponent,
  year: YearFormFieldComponent,
  url: UrlFormFieldComponent,
  record: ContactFormFieldComponent,
};

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit, OnChanges {
  get canShowHistory(): boolean {
    if (this.alwaysHideHistory === true) {
      return false;
    }

    return this.fieldDef?.show_history;
  }

  get isLocked(): boolean {
    return this.fieldDef?.locked;
  }

  get tooltipContent() {
    let tooltipContent = [];

    if (this.showInherited()) {
      if (this.data?.project?.parents?.length > 0) {
        tooltipContent.push("This field's value is inherited from the parent deal.");
      } else {
        tooltipContent.push("This field's value will be inherited by child deals.");
      }
    }

    tooltipContent.push(this.fieldDef?.description || this.fieldDef?.meta?.calc_code);

    return tooltipContent.filter(Boolean).join('\n');
  }

  get showTip() {
    return this.fieldDef?.description || this.fieldDef?.meta?.calc_code || this.showInherited();
  }

  @Input() fieldDef: FieldDef;
  @Input() model: any;
  @Input() data: any;
  @Input() readonly = false;
  @Input() alwaysHideHistory: boolean = null;
  @Input() doNotEvaluateChanges: boolean = false;
  @Input() isRequired: boolean = false;
  @Output() onUpdate: EventEmitter<PartialFormField> = new EventEmitter();
  @ViewChild(FormFieldDirective, { static: true }) formFieldHost: FormFieldDirective;

  @ViewChild('historyPanel')
  private historyPanel: TemplateRef<any>;

  @ViewChild('historyPanelOrigin')
  private historyPanelOrigin: ElementRef;

  private historyPanelOverlayRef: OverlayRef;
  private componentRef;

  private showInherited() {
    return this.fieldDef?.inherited;
  }

  constructor(
    private readonly overlay: Overlay,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer2: Renderer2,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.loadComponent();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (!this.doNotEvaluateChanges) {
      const difference: string[] = between(simpleChanges.model?.previousValue, simpleChanges.model?.currentValue);

      if (difference && !difference.includes(this.fieldDef?.name)) {
        return;
      }
    }

    if (this.componentRef) {
      if (isFunction((<FormFieldInterfaceComponent>this.componentRef.instance).updateChangedModel)) {
        if (this.componentRef.instance.inputControl?.nativeElement !== document.activeElement) {
          (<FormFieldInterfaceComponent>this.componentRef.instance).updateChangedModel(this.model);
          (<FormFieldInterfaceComponent>this.componentRef.instance).readonly = this.readonly;
        }
      } else {
        (<FormFieldInterfaceComponent>this.componentRef.instance).model = this.model;
      }
    }
  }

  loadComponent(): void {
    if (this.fieldDef?.type && formFieldComponents[this.fieldDef.type]) {
      if (this.model?.permissions) {
        this.readonly = !this.model.permissions?.is_editable;
        const user = this.store.selectSnapshot(UserState.getUser);
        const isAdmin = user.roles.some((role) => role.name === ReservedRole.Admin);
        if (this.fieldDef.name === 'record_owner_id' || this.fieldDef.name === 'team_id') {
          const canEditOwnerOrTeamField = this.model.record_owner_id === user.id || isAdmin;
          this.readonly = !canEditOwnerOrTeamField;
        }
      }

      const viewContainerRef = this.formFieldHost.viewContainerRef;
      viewContainerRef.clear();

      this.componentRef = viewContainerRef.createComponent(formFieldComponents[this.fieldDef.type]);
      const componentInstance = this.componentRef.instance as FormFieldInterfaceComponent;
      componentInstance.model = this.model;
      componentInstance.fieldDef = this.fieldDef;
      componentInstance.data = this.data;
      componentInstance.readonly = this.readonly;
      componentInstance.isRequired = this.isRequired;
      componentInstance.update = this.onUpdate;
      componentInstance.fieldMask = FieldMaskUtils.maskFormattingFunctionsMap[this.fieldDef.name];
    }
  }

  openHistoryPanel(): void {
    this.historyPanelOverlayRef = this.overlay.create({
      backdropClass: '',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.historyPanelOrigin.nativeElement)
        .withFlexibleDimensions()
        .withViewportMargin(64)
        .withLockedPosition()
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
    });

    this.historyPanelOverlayRef
      .attachments()
      .subscribe(() => this.renderer2.addClass(this.historyPanelOrigin.nativeElement, 'panel-opened'));

    const templatePortal = new TemplatePortal(this.historyPanel, this.viewContainerRef);

    this.historyPanelOverlayRef.attach(templatePortal);

    this.historyPanelOverlayRef.backdropClick().subscribe(() => {
      this.renderer2.removeClass(this.historyPanelOrigin.nativeElement, 'panel-opened');

      if (this.historyPanelOverlayRef && this.historyPanelOverlayRef.hasAttached()) {
        this.historyPanelOverlayRef.detach();
      }

      if (templatePortal?.isAttached) {
        templatePortal.detach();
      }
    });
  }
}
