import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';

import { Select } from '@ngxs/store';
import { FolderResourceState } from './state/state';
import { FolderResource, FileResource } from './folders.types';

declare type ModuleBaseUrlType = 'projects' | 'properties' | 'companies' | 'contacts' | 'tasks';
interface FolderUrlOptions {
  extraPathUrl?: string;
  folderId?: number | string;
  fileId?: number | string;
}

@Injectable({
  providedIn: 'root',
})
export class FoldersTabService {
  private _folder: BehaviorSubject<FolderResource | null>;
  private _file: BehaviorSubject<FileResource | null>;
  private _moduleId: string;
  private _moduleBaseUrl: ModuleBaseUrlType;
  private readonly _moduleBaseUrlTypes = ['projects', 'properties', 'contacts', 'companies', 'tasks'];
  private _accountId: string;

  @Select(FolderResourceState.getFolder) folderSelector$: Observable<FolderResource>;

  constructor(private _httpClient: HttpClient) {
    this._folder = new BehaviorSubject(null);
    this._file = new BehaviorSubject(null);
  }

  get folder$(): Observable<FolderResource> {
    return this._folder.asObservable();
  }

  get file$(): Observable<FileResource> {
    return this._file.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  set moduleId(val: string) {
    this._moduleId = val;
  }

  get moduleId() {
    return this._moduleId;
  }

  set moduleBaseUrl(val: ModuleBaseUrlType) {
    this._moduleBaseUrl = val;
  }

  get moduleBaseUrl() {
    return this._moduleBaseUrl;
  }

  searchFolders(search: string = ''): Observable<any> {
    const url = this.generateFolderResourceUrl({
      extraPathUrl: '/search',
    });

    return this._httpClient
      .post(url, {
        search: search,
      })
      .pipe(
        debounceTime(500),
        map((response) => {
          return response;
        }),
      );
  }

  getFolder(folderId?: number): Observable<FolderResource> {
    const url = this.generateFolderResourceUrl({
      folderId: folderId || '',
      fileId: undefined,
      extraPathUrl: '',
    });

    return this._httpClient.get<any>(url).pipe(
      map(({ folder_resource }) => {
        this._folder.next(folder_resource);
        return folder_resource;
      }),
    );
  }

  getFile(folderId: number, fileResourceId: number): Observable<FileResource> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId: fileResourceId,
      extraPathUrl: '',
    });

    return this._httpClient.get<any>(url).pipe(
      map(({ file_resource }) => {
        this._file.next(file_resource);
        return file_resource;
      }),
    );
  }

  getFileById(fileResourceId: number): Observable<FileResource> {
    const url = this.generateFileResourceUrl({
      fileId: fileResourceId,
      extraPathUrl: '',
    });

    return this._httpClient.get<any>(url).pipe(
      map(({ file_resource }) => {
        this._file.next(file_resource);
        return file_resource;
      }),
    );
  }

  createFolder(folderResource: FolderResource, skipNext: boolean = false): Observable<FolderResource> {
    const url = this.generateFolderResourceUrl({});

    return this.folderSelector$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .post<any>(url, {
            folder_resource: folderResource,
          })
          .pipe(
            map((reponse) => {
              // Return the new contact
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  updateFolder(folderResource: FolderResource): Observable<FolderResource> {
    const url = this.generateFolderResourceUrl({
      folderId: folderResource.id,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .put<any>(url, {
            folder_resource: folderResource,
          })
          .pipe(
            map((reponse) => {
              // Return the new contact
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  createFile(folderId: number, fileResource: FileResource, skipNext: boolean = false): Observable<FileResource> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient.post<any>(url, { file_resource: fileResource }).pipe(
          map((response) => {
            let newFile = response.file_resource;

            return newFile;
          }),
        ),
      ),
    );
  }

  deleteFile(folderId: number, fileResourceId: number): Observable<any> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId: fileResourceId,
    });

    return this._httpClient.delete<any>(url);
  }

  deleteFolder(folderId: number): Observable<any> {
    const url = this.generateFolderResourceUrl({
      folderId: folderId,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient.delete<any>(url).pipe(
          map((response) => {
            response.folder_resource;
          }),
        ),
      ),
    );
  }

  updateFile(folderId: number, fileResource: FileResource): Observable<FileResource> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId: fileResource.id,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient.put<any>(url, { file_resource: fileResource }).pipe(
          map((response) => {
            let newFile = response.file_resource;

            return newFile;
          }),
        ),
      ),
    );
  }

  uploadDocument(folderId: number, fileId: number, document: any, skipNext: boolean = false): Observable<FileResource> {
    const uploadData = new FormData();
    uploadData.append('document[attachment]', document);
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId,
      extraPathUrl: `/upload_document`,
    });
    return this.folderSelector$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient.post<any>(url, uploadData).pipe(
          map((response) => {
            let updatedFileResource = response.file_resource;
            return updatedFileResource;
          }),
        ),
      ),
    );
  }

  updateRanks(list: any[], fileResource: string): Observable<FileResource> {
    let params = [];
    list.map((item, index) => params.push([fileResource, item.id, index]));
    return this.folderSelector$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient.put<any>(`ranks`, { ranks: params }).pipe(
          map((response) => {
            let newFile;
            if (response) {
              newFile = response.file_resource;
            }

            return newFile;
          }),
        ),
      ),
    );
  }

  deleteDocument(folderId: number, fileId: number, documentId: number): Observable<any> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId,
      extraPathUrl: `/destroy_document?document_id=${documentId}`,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient.delete<any>(url).pipe(
          map((response) => {
            return response;
          }),
        ),
      ),
    );
  }

  getBase64Document(folderId: number, fileId: number, documentId: number): Observable<any> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId,
      extraPathUrl: `/download_base64?document_id=${documentId}`,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient.post<any>(url, {}).pipe(
          map((response) => {
            return response;
          }),
        ),
      ),
    );
  }

  uploadedExtractedDataToDocument(
    folderId: number,
    fileId: number,
    documentId: number,
    extactedData: any,
  ): Observable<any> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId,
      extraPathUrl: `/save_extracted_data?document_id=${documentId}`,
    });

    return this.folderSelector$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient.put<any>(url, { extracted_data: extactedData }).pipe(
          map((response) => {
            return response;
          }),
        ),
      ),
    );
  }

  shareFolder(folderId, emails: string[] = [], message: string = ''): Observable<any> {
    const url = this.generateFolderResourceUrl({
      folderId: folderId,
      extraPathUrl: '/share',
    });

    return this._httpClient
      .post<any>(url, {
        emails: emails.join(','),
        message: message,
      })
      .pipe(
        map((response) => {
          return response;
        }),
      );
  }

  sendToDocusign(folderId: number, fileId: number): Observable<any> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId,
      extraPathUrl: '/send_to_docusign',
    });

    return this._httpClient.post<any>(url, {}).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  shareFile(folderId, fileResourceId, emails: string[] = [], message: string = ''): Observable<any> {
    const url = this.generateFileResourceUrl({
      folderId: folderId,
      fileId: fileResourceId,
      extraPathUrl: '/share',
    });

    return this._httpClient.post<any>(url, { emails: emails.join(','), message: message }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  private generateFileResourceUrl(params: FolderUrlOptions): string {
    const accountIdParam = params.extraPathUrl?.includes('?') ? `${params.extraPathUrl}` : ``;

    if (this._moduleBaseUrlTypes.includes(this.moduleBaseUrl)) {
      const folderResourcePath = params.folderId ? `folder_resources/${params.folderId}/` : '';

      return `${this.moduleBaseUrl}/${this.moduleId}/${folderResourcePath}file_resources/${
        params.fileId || ''
      }${params.extraPathUrl || ''}${accountIdParam}`;
    } else {
      let baseUrl = `accounts/${this._accountId}/folder_resources/${params.folderId}/file_resources/${
        params.fileId || ''
      }${params.extraPathUrl || ''}`;

      if (this.moduleBaseUrl && this.moduleId) {
        baseUrl += `?related_to_type=${this.moduleBaseUrl}&related_to_id=${this.moduleId}`;
      }

      return baseUrl;
    }
  }

  private generateFolderResourceUrl(params: FolderUrlOptions): string {
    const accountIdParam = params.extraPathUrl?.includes('?') ? `${params.extraPathUrl}` : ``;

    if (this._moduleBaseUrlTypes.includes(this.moduleBaseUrl)) {
      return `${this.moduleBaseUrl}/${this.moduleId}/folder_resources/${params.folderId || ''}${
        params.extraPathUrl || ''
      }${accountIdParam}`;
    } else {
      let baseUrl = `accounts/${this._accountId}/folder_resources/${params.folderId || ''}${params.extraPathUrl || ''}`;

      if (this.moduleBaseUrl && this.moduleId) {
        baseUrl += `?related_to_type=${this.moduleBaseUrl}&related_to_id=${this.moduleId}`;
      }

      return baseUrl;
    }
  }
}
