import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { User, UserAssociation } from 'app/core/models/user.types';
import { Project, Property } from 'app/modules/projects/projects.types';
import { Contact } from 'app/modules/contacts/contacts.types';
import { Company } from 'app/modules/companies/companies.types';
import { FavoriteRecord } from './user.types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Observables
  _user: BehaviorSubject<User | null>;
  private _accountUserAssociation: BehaviorSubject<UserAssociation | null>;
  private _recentProjects: BehaviorSubject<Project[] | null>;
  private _recentContacts: BehaviorSubject<Contact[] | null>;
  private _recentCompanies: BehaviorSubject<Company[] | null>;
  private _recentProperties: BehaviorSubject<Contact[] | null>;
  private _favoriteRecords: BehaviorSubject<any | null>;
  private _role: BehaviorSubject<any | null>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this._user = new BehaviorSubject(null);
    this._role = new BehaviorSubject(null);
    this._recentProjects = new BehaviorSubject(null);
    this._recentContacts = new BehaviorSubject(null);
    this._recentCompanies = new BehaviorSubject(null);
    this._recentProperties = new BehaviorSubject(null);
    this._favoriteRecords = new BehaviorSubject(null);
    this._accountUserAssociation = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Setter and getter for user
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // Setter and getter for user
  set role(value: any) {
    // Store the value
    this._role.next(value);
  }

  get role$(): Observable<any> {
    return this._role.asObservable();
  }

  get recentProjects$(): Observable<Project[]> {
    return this._recentProjects.asObservable();
  }

  get recentContacts$(): Observable<Contact[]> {
    return this._recentContacts.asObservable();
  }

  get recentCompanies$(): Observable<Company[]> {
    return this._recentCompanies.asObservable();
  }

  get recentProperties$(): Observable<Contact[]> {
    return this._recentProperties.asObservable();
  }

  get favoriteRecords$(): Observable<any> {
    return this._favoriteRecords.asObservable();
  }

  set accountUserAssociation(value: UserAssociation) {
    // Store the value
    this._accountUserAssociation.next(value);
  }

  get accountUserAssociation$(): Observable<UserAssociation> {
    return this._accountUserAssociation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  get(): Observable<User> {
    return this._httpClient.get<{ user: User }>('user').pipe(
      map((response) => {
        return response.user;
      }),
    );
  }

  /**
   * Update the user data
   *
   * @param user
   */
  update(user: User): Observable<User> {
    return this._httpClient.put<any>('user', { user }).pipe(
      map((response) => {
        // Execute the observable
        this._user.next(response.user);
        return response.user;
      }),
    );
  }

  uploadProfilePhoto(file: File): Observable<User> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this._httpClient.post<any>('user/avatar', uploadData).pipe(
      map((response) => {
        // Execute the observable
        this._user.next(response.user);
        return response.user;
      }),
    );
  }

  deleteProfilePhoto(): Observable<User> {
    return this._httpClient.delete<{ user: User }>('user/avatar').pipe(
      map((response) => {
        this._user.next(response.user);
        return response.user;
      }),
      catchError((err) => of(err)),
    );
  }

  getRecentItems(accountId: string): Observable<any> {
    return this._httpClient.get<any>(`user/recent_records`).pipe(
      map(({ recent_records }: { recent_records: any }) => {
        this._recentProjects.next(recent_records?.projects);
        this._recentContacts.next(recent_records?.contacts);
        this._recentCompanies.next(recent_records?.companies);
        this._recentProperties.next(recent_records?.properties);
        return recent_records;
      }),
    );
  }

  getRecentProjects(accountId: string): Observable<Project[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Project`).pipe(
      map((response) => {
        // Execute the observable
        this._recentProjects.next(response?.projects);
        return response?.projects;
      }),
    );
  }

  getRecentContacts(accountId: string): Observable<Project[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Contact`).pipe(
      map((response) => {
        // Execute the observable
        this._recentContacts.next(response?.contacts);
        return response?.contacts;
      }),
    );
  }

  getRecentCompanies(accountId: string): Observable<Company[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Company`).pipe(
      map((response) => {
        // Execute the observable
        this._recentCompanies.next(response?.companies);
        return response?.companies;
      }),
    );
  }

  getRecentProperties(accountId: string): Observable<Property[]> {
    return this._httpClient.get<any>(`user/recent_records?related_to_type=Property`).pipe(
      map((response) => {
        // Execute the observable
        this._recentProperties.next(response?.properties);
        return response?.properties;
      }),
    );
  }

  getAccountUserAssociation(accountId: string): Observable<UserAssociation[]> {
    return this._httpClient.get<any>(`user/account_user_association`).pipe(
      map((response) => {
        // Execute the observable
        this._accountUserAssociation.next(response?.user_association);
        return response?.user_association;
      }),
    );
  }

  getUserSignature(): Observable<UserAssociation[]> {
    return this._httpClient.get<any>(`signature`).pipe(
      map((response) => {
        return response.body;
      }),
      catchError((error) => of('')),
    );
  }

  saveUserSignature(signature: any): Observable<User> {
    return this._httpClient.put<any>('signature', signature).pipe(
      map((response) => {
        return response.body;
      }),
    );
  }

  /**
   *
   * @param settings FormGroup
   * @param applyToEveryone boolean to apply changes to all users from the same account
   * @returns updated user response object: Observable of type User
   */
  saveSettings(settings: any, applyToEveryone: boolean = false): Observable<User> {
    let params = new HttpParams();

    if (applyToEveryone) {
      params = params.set('apply_changes_to_everyone', 'true');
    }

    return this._httpClient.put<any>('user/settings', { settings: settings }, { params }).pipe(
      map((response) => {
        this._user.next(response.user);
        return response.user;
      }),
    );
  }

  getPusherAuth(): Observable<any> {
    return this._httpClient.post<any>('user/pusher_auth', {}).pipe(
      map((response) => {
        return response.pusher_token;
      }),
    );
  }

  getFavoriteRecords(accountId: string): Observable<Property[]> {
    return this.favoriteRecords$.pipe(
      take(1),
      switchMap((favoriteRecords) =>
        this._httpClient.get<any>(`user/favorite_records`).pipe(
          map((response) => {
            // Execute the observable

            this._favoriteRecords.next(response?.favorite_records);
            return response?.favorite_records;
          }),
        ),
      ),
    );
  }

  getFavoriteRecordsByType<T>(accountId: number, relatedToType: string): Observable<FavoriteRecord<T>[]> {
    return this._httpClient
      .get<any>(`user/favorite_records?related_to_type=${relatedToType}`)
      .pipe(map((response) => response.favorite_records));
  }

  toggleFavoriteRecord<T>(
    accountId: number,
    relatedToType: string,
    relatedToId: number,
  ): Observable<FavoriteRecord<T>[]> {
    return this._httpClient
      .post<any>(`user/favorite_records/toggle?related_to_type=${relatedToType}&related_to_id=${relatedToId}`, {})
      .pipe(map((response) => response.favorite_records));
  }

  toggleFavoritesRecord(accountId: string, relatedToType: string, relatedToId: any): Observable<Property[]> {
    return this.favoriteRecords$.pipe(
      take(1),
      switchMap((favoriteRecords) =>
        this._httpClient
          .post<any>(`user/favorite_records/toggle?related_to_type=${relatedToType}&related_to_id=${relatedToId}`, {})
          .pipe(
            map((response) => {
              // Execute the observable
              this._favoriteRecords.next(response?.favorite_records);
              return response?.favorite_records;
            }),
          ),
      ),
    );
  }

  isFavorited(related_to_type, related_to_id): boolean {
    let favorites = this._favoriteRecords.value;

    if (favorites) {
      const foundFavorite =
        favorites.find((item) => item.related_to_id == related_to_id && item.related_to_type == related_to_type) ||
        null;

      if (foundFavorite) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
