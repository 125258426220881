import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Comment } from 'app/core/models/comment.types';

const pipelinesCacheBuster$ = new Subject<void>();
const projectCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  // Private
  private _comments: BehaviorSubject<Comment[] | null>;

  private _accountId: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this._comments = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for contacts
   */
  get comments$(): Observable<Comment[]> {
    return this._comments.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  get accountId() {
    return this._accountId;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getComments(commentableId: number, commentableType: string): Observable<Comment[]> {
    return this._httpClient
      .get<any>(`comments?commentable_id=${commentableId}&commentable_type=${commentableType}`)
      .pipe(
        map((response) => {
          this._comments.next(response.comments);
          return response.comments;
        }),
      );
  }

  /**
   * Create comment
   * @param comment
   */
  createComment(comment: Comment): Observable<Comment> {
    return this.comments$.pipe(
      take(1),
      switchMap((comments) =>
        this._httpClient.post<any>(`comments`, { comment: comment }).pipe(
          map((response) => {
            let newComment = response.comment;
            comments.unshift(newComment);
            this._comments.next(comments);
            return newComment;
          }),
        ),
      ),
    );
  }

  /**
   * Update comment
   * @param comment
   */
  updateComment(comment: Comment): Observable<Comment> {
    return this.comments$.pipe(
      take(1),
      switchMap((comments) =>
        this._httpClient.put<any>(`comments/${comment.id}`, { comment: comment }).pipe(
          map((response) => {
            let updatedComment = response.comment;

            // Find the index of the updated comment
            const index = comments.findIndex((item) => item.id === updatedComment.id);

            // Update the contact
            comments[index] = updatedComment;

            // Update the contacts
            this._comments.next(comments);

            return updatedComment;
          }),
        ),
      ),
    );
  }

  /**
   * Delete comment
   * @param id
   */
  deleteComment(id: number): Observable<any> {
    return this.comments$.pipe(
      take(1),
      switchMap((comments) =>
        this._httpClient.delete<any>(`comments/${id}`).pipe(
          map((reponse) => {
            // Find the index of the deleted label within the labels
            const index = comments.findIndex((item) => item.id === id);

            // Delete the label
            comments.splice(index, 1);

            // Update the labels
            this._comments.next(comments);

            // Return the deleted status
            return reponse.comment;
          }),
        ),
      ),
    );
  }
}
