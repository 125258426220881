import { Pipe, PipeTransform } from '@angular/core';
import { RelatedToTypeTitlePipe } from './related-to-type.pipe';

@Pipe({
  name: 'sortRelatedToType',
})
export class SortRelatedToTypePipe implements PipeTransform {
  constructor(private relatedToTypeTitlePipe: RelatedToTypeTitlePipe) {}

  transform(value: any[], customObjects: any): any[] {
    if (!value || !customObjects) {
      return value;
    }
    return value.sort((a, b) => {
      const titleA = this.relatedToTypeTitlePipe.transform(a.type, customObjects);
      const titleB = this.relatedToTypeTitlePipe.transform(b.type, customObjects);
      return titleA.localeCompare(titleB);
    });
  }
}