/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError, catchError } from 'rxjs';
import { TsSnackbarService } from 'tsui';

const ERROR_MESSAGES = {
  404: 'The page/asset you are looking for does not exist.',
  504: 'The server is taking too long to respond. Please try again later.',
};
@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {
  constructor(public snackBar: TsSnackbarService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 404 || error.status === 504) {
          this.snackBar.open(ERROR_MESSAGES[error.status], 'error');
        }

        return throwError(() => error);
      }),
    );
  }
}
