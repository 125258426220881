<ng-container *ngIf="isScreenSmall">
  <treo-vertical-navigation
    class="bg-cool-gray-900 theme-dark"
    [appearance]="'classic'"
    [mode]="'over'"
    [name]="'verticalMainNavigation'"
    [navigation]="menuItems"
    [opened]="false"
  >
    <div treoVerticalNavigationContentHeader>
      <div class="logo">
        <img src="assets/images/logo/ts-logo.png" />
      </div>
    </div>
  </treo-vertical-navigation>
</ng-container>
<div class="wrapper">
  <div #header class="header" [class.new-ui]="newUi">
    <div class="logo" *ngIf="!isScreenSmall">
      <img class="logo-text" src="assets/images/logo/ts-logo.png" />
      <img class="logo-text-on-dark" src="assets/images/logo/logo-text-on-dark.svg" />
      <img class="logo-base" src="assets/images/logo/ts-logo.png" />
    </div>
    <ng-container *ngIf="!fromAccounts && account.id">
      <!-- Horizontal navigation -->
      <div class="main-navigation" #mainNavigation *ngIf="!isScreenSmall">
        <treo-horizontal-navigation
          name="mainNavigation"
          [navigation]="menuItems"
          id="mainNavigation"
        ></treo-horizontal-navigation>
      </div>

      <!-- Navigation toggle button -->
      <button
        class="navigation-toggle-button"
        *ngIf="isScreenSmall"
        name="mainNavigation"
        id="mainNavigation"
        mat-icon-button
        (click)="toggleNavigation('verticalMainNavigation')"
      >
        <mat-icon [svgIcon]="'menu'"></mat-icon>
      </button>

      <ng-container *ngIf="!isTaskCollaborator">
        <div class="menu-actions">
          <div class="spacer"></div>
          <search [appearance]="'bar'" [account]="account"></search>
          <notifications [notifications]="[]"></notifications>
          <calculator></calculator>
          <div *ngIf="canAccessEthan" class="h-6 w-6 mr-2 ml-2 cursor-pointer">
            <img
              src="assets/icons/ethan-avatar.svg"
              class=""
              [ngClass]="{
                'opacity-50': !isFloatingEthanVisible,
                'cursor-pointer transition-opacity duration-200': true,
              }"
              (click)="toggleFloatingEthan()"
              [title]="isFloatingEthanVisible ? 'Disable Ethan Bot' : 'Enable Ethan Bot'"
              alt="Ethan Avatar"
            />
          </div>
          <span class="rnw-badge">
            <mat-icon class="cursor-pointer" [fontIcon]="'campaign'"></mat-icon>
          </span>
          <support-hub></support-hub>
        </div>
      </ng-container>
      <user *ngIf="data?.user" [user]="data.user" [isTaskCollaborator]="isTaskCollaborator"></user>
    </ng-container>
  </div>

  <div class="content bg-white">
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>

<ng-container *ngIf="canAccessEthan && isFloatingEthanVisible">
  <div
    #chatButtonWrapper
    [ngClass]="'chat-button-wrapper'"
    [class.dragging]="isDragging"
    (mousedown)="onDragStart($event, chatButtonWrapper)"
  >
    <ng-container *ngIf="!chatIsOpen">
      <button mat-fab color="primary" class="floating-chat chat-button" (click)="openCloseChat()">
        <img [ngClass]="'select-none pointer-events-none'" src="assets/icons/ethan-e.svg" alt="AI Ability" />
      </button>
    </ng-container>
    <ng-container *ngIf="chatIsOpen">
      <button mat-fab color="primary" class="floating-chat chat-button" (click)="openCloseChat()">
        <mat-icon>close</mat-icon>
      </button>
    </ng-container>
  </div>
</ng-container>
