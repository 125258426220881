import { Directive, ElementRef, OnInit, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { AddressPart, Place, googlePlacesDictionary, parsePlace } from '../models/address';

declare let google: any;

@Directive({
  selector: '[google-place]',
})
export class GooglePlacesDirective implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSelectParts: EventEmitter<AddressPart> = new EventEmitter();

  private autoComplete: any;
  private autoCompleteService: any;
  private placesService: any;

  private get element(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }

  constructor(private readonly elementRef: ElementRef, private readonly ngZone: NgZone) {}

  ngOnInit(): void {
    this.autoComplete = new google.maps.places.Autocomplete(this.element);
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(this.element);

    google.maps.event.addListener(this.autoComplete, 'place_changed', () => {
      this.handlePlaceSelect();
    });
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.autoCompleteService.getPlacePredictions({ input: this.element.value }, (predictions, status) => {
        if (predictions.length > 0) {
          this.getPlaceDetails(predictions[0]);
        }
      });
    }
  }

  private handlePlaceSelect(preSelectedPlace: any = null): void {
    const selectedPlace: Place = preSelectedPlace ? preSelectedPlace : this.autoComplete.getPlace();
    let results = {} as AddressPart;
    let formattedAddress = '';

    if (selectedPlace && selectedPlace.address_components && selectedPlace.geometry) {
      results = parsePlace(selectedPlace);

      results['route'] = results['route'] ?? selectedPlace.name;
      formattedAddress = selectedPlace.formatted_address;
    }

    this.ngZone.run(() => {
      this.onSelectParts.emit(results);
      this.onSelect.emit(formattedAddress);
    });
  }

  private getPlaceDetails(prediction: any): void {
    this.placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === 'OK') {
        this.handlePlaceSelect(place);
      }
    });
  }
}
