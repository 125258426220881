import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { FolderResource, FileResource } from 'app/shared/modules/folders/folders.types';

@Injectable({
  providedIn: 'root',
})
export class AccountFoldersService {
  // Private
  private _folder: BehaviorSubject<FolderResource | null>;
  private _file: BehaviorSubject<FileResource | null>;
  private _accountId: string;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the private defaults
    this._folder = new BehaviorSubject(null);
    this._file = new BehaviorSubject(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for folder
   */
  get folder$(): Observable<FolderResource> {
    return this._folder.asObservable();
  }

  /**
   * Getter for contact
   */
  get file$(): Observable<FileResource> {
    return this._file.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  searchFolders(search: string = ''): Observable<any> {
    return this._httpClient.post(`accounts/${this._accountId}/folder_resources/search`, { search: search }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  /**
   * Get contacts
   *
   * @param sortField
   * @param sortDirection
   */
  getFolder(folderId?: number): Observable<FolderResource> {
    if (folderId) {
      return this._httpClient.get<any>(`accounts/${this._accountId}/folder_resources/${folderId}`).pipe(
        tap((response: { folder_resource: FolderResource }) => {
          this._folder.next(response.folder_resource);
        }),
        map((response: { folder_resource: FolderResource }) => {
          return response.folder_resource;
        }),
      );
    } else {
      return this._httpClient
        .get<{ folder_resource: FolderResource }>(`accounts/${this._accountId}/folder_resources`)
        .pipe(
          tap((response: { folder_resource: FolderResource }) => {
            this._folder.next(response.folder_resource);
          }),
          map((response: { folder_resource: FolderResource }) => {
            return response.folder_resource;
          }),
        );
    }
  }

  getFile(folderId: number, fileResourceId: number): Observable<FileResource> {
    return this._httpClient
      .get<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileResourceId}`)
      .pipe(
        map((response: any) => {
          this._file.next(response.file_resource);
          return response.file_resource;
        }),
      );
  }

  createFolder(folderResource: FolderResource): Observable<FolderResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(`accounts/${this._accountId}/folder_resources`, {
            folder_resource: folderResource,
          })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              this._folder.next(reponse.folder_resource);

              // Return the new contact
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  updateFolder(folderResource: FolderResource): Observable<FolderResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .put<any>(`accounts/${this._accountId}/folder_resources/${folderResource.id}`, {
            folder_resource: folderResource,
          })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              //this._folder.next(reponse.folder_resource);

              // Return the new contact
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  createFile(folderId: number, fileResource: FileResource): Observable<FileResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources`, {
            file_resource: fileResource,
          })
          .pipe(
            map((response) => {
              let newFile = response.file_resource;
              if (folder) {
                let files = [newFile, ...folder.file_resources];
                let newFolder = folder;
                newFolder.file_resources = files;
                // Update the contacts with the new contact
                this._folder.next(newFolder);
              }

              return newFile;
            }),
          ),
      ),
    );
  }

  deleteFile(folderId: number, fileResourceId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .delete<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileResourceId}`)
          .pipe(
            map((response) => {
              if (folder) {
                let files = folder.file_resources;
                const index = files.findIndex((item) => item.id === fileResourceId);
                files.splice(index, 1);
                folder.file_resources = files;
                this._folder.next(folder);
              }
              response.file_resource;
            }),
          ),
      ),
    );
  }

  updateFile(folderId: number, fileResource: FileResource): Observable<FileResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .put<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileResource.id}`, {
            file_resource: fileResource,
          })
          .pipe(
            map((response) => {
              let newFile = response.file_resource;
              let files = [newFile, ...folder.file_resources];
              let newFolder = folder;
              newFolder.file_resources = files;
              // Update the contacts with the new contact
              this._folder.next(newFolder);

              return newFile;
            }),
          ),
      ),
    );
  }

  uploadDocument(folderId: number, fileId: number, document: any): Observable<FileResource> {
    const uploadData = new FormData();
    uploadData.append('document[attachment]', document);

    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(
            `accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileId}/upload_document`,
            uploadData,
          )
          .pipe(
            map((response) => {
              let updatedFileResource = response.file_resource;

              if (folder) {
                const index = folder.file_resources.findIndex((item) => item.id === updatedFileResource.id);
                folder.file_resources[index] = updatedFileResource;
                this._folder.next(folder);
              }

              return updatedFileResource;
            }),
          ),
      ),
    );
  }

  deleteDocument(folderId: number, fileId: number, documentId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .delete<any>(
            `accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileId}/destroy_document?document_id=${documentId}`,
          )
          .pipe(
            map((response) => {
              return response;
            }),
          ),
      ),
    );
  }

  getAllFiles(excludeDocuments: boolean = false): Observable<FileResource[]> {
    return this._httpClient
      .get<any>(`accounts/${this._accountId}/file_resources?exclude_documents=${excludeDocuments}`)
      .pipe(
        map((response: any) => {
          return response.file_resources;
        }),
      );
  }
}
