import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Image } from 'app/modules/projects/projects.types';
import { CustomObjectValue, CustomObjectValueResponse } from '../custom-objects.types';

@Injectable({
  providedIn: 'root',
})
export class CustomObjectsService {
  constructor(private readonly httpClient: HttpClient) {}

  getValueById(id: number, customObjectId: number, accountId: number): Observable<CustomObjectValue> {
    return this.httpClient.get<CustomObjectValue>(
      `accounts/${accountId}/custom_objects/${customObjectId}/values/${id}`,
    );
  }

  getValues(
    accountId: number,
    customObjectId: number,
    relatedToType: string | undefined,
    relatedToId: number | undefined,
    page = 0,
    size = 10,
    sort = 'id',
    order: 'asc' | 'desc' | '' = 'asc',
    search = '',
  ): Observable<CustomObjectValueResponse> {
    let url = `accounts/${accountId}/custom_objects/${customObjectId}/values`;

    if (relatedToType) {
      url += `?related_to_type=${relatedToType}`;
    }

    if (relatedToId) {
      url += `&related_to_id=${relatedToId}`;
    }

    return this.httpClient
      .get<{ count: number; results: CustomObjectValue[] }>(url, {
        params: {
          page: (page + 1).toString(),
          per_page: size.toString(),
          sort,
          order,
          q: search,
        },
      })
      .pipe(
        map((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const start = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page,
            lastPage: lastPage,
            startIndex: start,
            endIndex: end,
          };

          return {
            pagination,
            values: response.results,
          };
        }),
      );
  }

  createValue(accountId: number, customObjectId: number, value: CustomObjectValue): Observable<CustomObjectValue> {
    return this.httpClient
      .post<{ value: CustomObjectValue }>(`accounts/${accountId}/custom_objects/${customObjectId}/values`, value)
      .pipe(map((response) => response.value));
  }

  updateValue(
    accountId: number,
    customObjectId: number,
    customObjectValueId: number,
    value: Partial<CustomObjectValue>,
  ): Observable<CustomObjectValue> {
    return this.httpClient
      .put<{
        value: CustomObjectValue;
      }>(`accounts/${accountId}/custom_objects/${customObjectId}/values/${customObjectValueId}`, value)
      .pipe(map((response) => response?.value));
  }

  deleteValue(accountId: number, customObjectId: number, customObjectValueId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `accounts/${accountId}/custom_objects/${customObjectId}/values/${customObjectValueId}`,
    );
  }

  generateCustomObjectValueDocument(
    accountId: number,
    customObjectId: number,
    customObjectValueId: number,
    format: string = 'json',
    file_resource_id: number = null,
    sendToDocusign: boolean = null,
  ): Observable<any> {
    return this.httpClient
      .get<any>(
        // eslint-disable-next-line max-len
        `accounts/${accountId}/custom_objects/${customObjectId}/values/${customObjectValueId}?format=${format}&file_resource_id=${file_resource_id}&send_to_docusign=${sendToDocusign}`,
      )
      .pipe(
        map((response) => {
          if (format == 'pdf' || format == 'docx') {
            return response;
          } else {
            return response.value;
          }
        }),
      );
  }

  uploadValueImage(accountId: number, customObjectValueId: number, file: File): Observable<Image> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this.httpClient
      .post<{ image: Image }>(`images?value_id=${customObjectValueId}`, uploadData)
      .pipe(map(({ image }) => image));
  }

  deleteValueImage(accountId: number, imageId: number): Observable<void> {
    return this.httpClient.delete<void>(`images/${imageId}`);
  }
}
