<a class="item" [href]="item.link" (click)="onItemClick($event)">
  <div class="item__badge" [ngClass]="item.relatedToType.toLowerCase()">{{ getLabel(item.relatedToType) }}</div>
  <div *ngIf="item.showImage" class="item__image">
    <img *ngIf="item.avatar; else avatarPlaceholder" [src]="item.avatar" />
    <ng-template #avatarPlaceholder>
      <mat-icon svgIcon="account_circle"></mat-icon>
    </ng-template>
  </div>
  <div class="item__title">
    <span>{{ item.title }} </span>
    <p class="item__title__info">
      <span class="item__title__info__highlight">
        <span class="item__title__info__highlight__label">ID:</span>: <span [innerHTML]="item.id"></span>&nbsp;
      </span>
      <span class="item__title__info__highlight" *ngFor="let highlight of item.highlights">
        <span class="item__title__info__highlight__label">{{ highlight.label }}</span
        >: <span [innerHTML]="highlight.content | highlighter: query : 'highlight-result' : 'partial'"></span>&nbsp;
      </span>
    </p>
  </div>
  <div class="item__status" *ngIf="projectStageName">
    <span>Status</span>
    <p [matTooltip]="projectStageName" [matTooltipShowDelay]="500" tooltipIfEllipsis>{{ projectStageName }}</p>
  </div>
  <div class="item__date">
    <span>Created At</span>
    <p>{{ item.created_at | date: 'MMM dd, Y h:mm a' }}</p>
  </div>
</a>
