import { AfterViewInit, DestroyRef, Directive, ElementRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { NewPermission, Permission } from '@core/enums';
import { PERMISSION_DENY_LABEL } from '@shared/literals';
import { AuthService } from 'app/core';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

type AuthorizeValue = NewPermission | Permission | boolean;

@Directive({
  selector: '[appAuthorize]',
  providers: [MatTooltip],
})
export class AuthorizeDirective implements AfterViewInit {
  @Input() denyAction: 'disable' | 'hide' = 'hide';
  @Input() appAuthorize: AuthorizeValue | AuthorizeValue[];

  private isAuthorized = false;

  private get htmlElement(): any {
    return this.elementRef.nativeElement;
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly authService: AuthService,
    private readonly tooltip: MatTooltip,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit(): void {
    if (Array.isArray(this.appAuthorize)) {
      this.isAuthorized = this.appAuthorize.every((permission: AuthorizeValue) => this.hasPermission(permission));
    } else {
      this.isAuthorized = this.hasPermission(this.appAuthorize);
    }

    if (this.isAuthorized) {
      return;
    }

    this.htmlElement.removeAllListeners();
    if (this.denyAction === 'disable') {
      this.handleDisable();
    } else {
      this.handleHide();
    }
  }

  private hasPermission(authorizeValue: AuthorizeValue): boolean {
    let checkResult = false;

    if (typeof authorizeValue === 'boolean') {
      checkResult = authorizeValue;
    } else if (authorizeValue && this.isPermission(authorizeValue)) {
      checkResult = this.authService.hasPermission(authorizeValue as Permission | NewPermission);
    }

    return checkResult;
  }

  private isPermission(authorizeValue: string): boolean {
    let enumKey = authorizeValue.replace('_OLD', '');

    return Permission[enumKey as string] || NewPermission[enumKey as string];
  }

  private handleDisable(): void {
    this.htmlElement.setAttribute('disabled', 'true');
    // hack: setting the disabled attribute on the element doesn't update the mat-buttons disabled class
    this.htmlElement.querySelectorAll('button').forEach((child) => child.classList.add('mat-button-disabled'));

    this.htmlElement.classList.add('cursor-not-allowed');

    fromEvent(this.htmlElement, 'click', { capture: true })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
      });

    fromEvent(this.htmlElement, 'mouseenter', { capture: true })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.tooltip.message = PERMISSION_DENY_LABEL;
        this.tooltip.show();
      });

    fromEvent(this.htmlElement, 'mouseleave', { capture: true })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.tooltip.hide();
      });
  }

  private handleHide(): void {
    this.htmlElement.getAttributeNames().forEach((attr) => {
      this.htmlElement.removeAttribute(attr);
    });
    this.htmlElement.classList.add('hidden');
  }
}
