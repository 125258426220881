import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SavedView } from 'app/core/models/account.types';
import { AccountService } from 'app/core/services';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsResolver {
  constructor(private _accountService: AccountService) {}

  resolve(): Observable<SavedView[]> {
    return this._accountService.getSavedViews();
  }
}

@Injectable({
  providedIn: 'root',
})
export class SavedViewResolver {
  constructor(
    private _router: Router,
    private _accountService: AccountService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SavedView> {
    return this._accountService.getSavedViewById(route.paramMap.get('saved_view_id')).pipe(
      switchMap((savedView) => {
        if (savedView.definition === null) {
          return this._accountService.updateSavedView({
            ...savedView,
            definition: JSON.stringify({
              table: { fields: ['name', 'phone', 'company_name', 'tag_list'] },
            }),
          });
        }

        return of(savedView);
      }),
      // Error here means the requested contact is not available
      catchError((error) => {
        // Log the error
        console.error(error);

        // Get the parent url
        const parentUrl = state.url.split('/').slice(0, -1).join('/');

        // Navigate to there
        this._router.navigateByUrl(parentUrl);

        // Throw an error
        return throwError(error);
      }),
    );
  }
}
