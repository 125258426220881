export const environment = {
  production: true,
  baseUrl: 'https://app.termsheetstage.com',
  apiVersion: 'api/v2',
  versionCheckURL: 'https://dashboard.termsheetstage.com/version.json',
  cookieDomain: 'termsheetstage.com',
  appDomain: 'termsheetstage.com',
  tilesApi: {
    url: 'https://staging.parceled.com',
    authToken: '50ED635ABAB395CAFA86',
  },
  google: {
    streetViewKey: 'AIzaSyDv7GjiEXfaV-oWTZBL5gJcwniQTBElk0I',
  },
  mapbox: {
    accessToken: 'pk.eyJ1Ijoicm9nZXNtaXRoIiwiYSI6ImNqdHB2OHg5azAwbzA0M3BlZ3dxanZ2NXcifQ.PBWyfzuqPZMB6EaL6mQdvQ',
  },
  env: 'stagingUI',
  dataDog: {
    env: 'staging',
    applicationId: '85021521-7384-462b-9cdd-488274177f7f',
    clientToken: 'pubd6650380605cdb2ee230a12936ee9069',
    site: 'datadoghq.com',
    service: 'termsheet-frontend',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  },
  pusher: {
    instanceLocator: 'v1:us1:293dfee0-776e-4024-accd-63f80b5d820f',
    instanceId: 'd5149367-2d46-462f-82c3-8e838a3b754f',
    channelApp: {
      key: '92535bc5500134da1eaa',
      cluster: 'us2',
    },
  },
  rollbar: {
    accessToken: '1b1a90d4408b492f88af7ce6b2f9802a',
    codeVersion: '92689fde4',
    code_version: '92689fde4',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'stagingUI',
    source_map_enabled: true,
    guess_uncaught_frames: true,
  },
  analytics: {
    segmentWriteKey: 'XJIvenWeIi1EeCVOxV3ad5F1YYPXOoNH',
  },
  syncfusion: {
    licenseKey: 'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5fd3VXQ2ddVUB1XUE=',
  },
  hubspot: {
    chatbotScriptId: '6152449',
  },
  ethanAbilities: {
    apiKey: 'ea-aOzGvwRxpv6KI0G3zgK088i6vw8qWUxNj-aLEheA5Ic',
  },
  enableReduxDevtools: false,
  ethanAssistant: {
    apiKey: 'ea-5a2HNrFMtJlGRCcF12fXIuM8zcQYnKSslxjhb6JsVMY',
    url: 'https://ai-assistant.termsheet.com/api/v1',
    env: 'stage',
  },
  frontEndCodeVersion: '92689fde4',
  agGridLicenseKey:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-064514}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TermSheet}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TermSheet}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{TermSheet}_need_to_be_licensed___{TermSheet}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_August_2025}____[v3]_[01]_MTc1NDA4OTIwMDAwMA==a6ae0c4c52035689fb1c57902d2bf306',
};
