export interface AddressPart {
  administrative_area_level_1: string;
  country: string;
  lat: number;
  lng: number;
  locality: string;
  route: string;
  postal_code?: string;
  street_number?: string;
  sublocality_level_1?: string;
}

export const googlePlacesDictionary = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
  sublocality_level_1: 'short_name',
};

export type Place = {
  geometry?: {
    location: {
      lat: () => any;
      lng: () => any;
    };
  };
  address_components: any[];
  formatted_address: string;
  name: string;
};

export const parsePlace = (selectedPlace: Place): AddressPart => {
  const results = {} as AddressPart;

  selectedPlace.address_components?.forEach((addressComponent) => {
    const [addressType] = addressComponent.types;

    if (googlePlacesDictionary[addressType]) {
      results[addressType] = addressComponent[googlePlacesDictionary[addressType]];
    }
  });

  if (selectedPlace?.geometry || selectedPlace?.geometry?.location) {
    results['lat'] = selectedPlace.geometry.location.lat();
    results['lng'] = selectedPlace.geometry.location.lng();
  } else {
    console.error(`No geometry location found in place ${selectedPlace?.name}`);
  }

  return results;
};
