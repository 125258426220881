import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TreoAnimations } from 'tsui/@treo';
import { Account, FieldDef } from 'app/core/models/account.types';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { RelatedToType } from 'app/shared/enums';
import { Select, Store } from '@ngxs/store';
import { FieldDefsState } from 'app/state/fields/state';
import { Field } from 'app/state/fields/actions';
import { Project, Property } from 'app/modules/projects/projects.types';
import { Contact } from 'app/modules/contacts/contacts.types';
import { SearchResultHighlight, SearchResultItem } from '../../../shared/models/global-search';
import { GlobalSearchService } from 'app/services/global-search.service';
import { Dictionary } from 'app/shared/models';
import { Company } from 'app/modules/companies/companies.types';
import { Note } from 'app/shared/modules/notes/notes.types';

type RelatedToTypeEntity = {
  relatedToType: RelatedToType;
};

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'treoSearch',
  animations: TreoAnimations,
})
export class SearchComponent extends OnDestroyMixin implements OnInit {
  results: any[] = null;
  searchControl: UntypedFormControl;
  ui = { isLoading: false };

  private _appearance: 'basic' | 'bar';
  private _opened: boolean;
  private fieldDefs: FieldDef[] = [];
  private urlForNote = (value: Note): string => {
    switch (value.noteable_type) {
      case 'Company':
        return `/companies/${value.id}`;
      case 'Contact':
        return `/contacts/${value.noteable_id}`;
      case 'Project':
        return `/projects/${value.pipeline_id}/${value.noteable_id}`;
      case 'Property':
        return `/properties/${value.noteable_id}`;
    }
  };
  private searchableTypes = {
    [RelatedToType.Project]: {
      map: (value: Project & RelatedToTypeEntity): SearchResultItem => {
        return {
          title: value.title,
          created_at: value.created_at,
          relatedToType: value.relatedToType,
          link: `/projects/${value.pipeline_id}/${value.id}`,
          avatar: value.featured_image_url,
          showImage: true,
          projectStage: value.project_stage,
          id: value.id,
        };
      },
    },
    [RelatedToType.Property]: {
      map: (value: Property & RelatedToTypeEntity): SearchResultItem => {
        return {
          title: value.name,
          created_at: value.created_at as string,
          relatedToType: value.relatedToType,
          link: `/properties/${value.id}`,
          showImage: false,
          id: value.id?.toString(),
        };
      },
    },
    [RelatedToType.Contact]: {
      map: (value: Contact & RelatedToTypeEntity): SearchResultItem => {
        return {
          title: value.name,
          created_at: value.created_at,
          relatedToType: value.relatedToType,
          link: `/contacts/${value.id}`,
          avatar: value.avatar_url,
          showImage: true,
          id: value.id?.toString(),
        };
      },
    },
    [RelatedToType.Company]: {
      map: (value: Company & RelatedToTypeEntity): SearchResultItem => {
        return {
          title: value.name,
          created_at: value.created_at,
          relatedToType: value.relatedToType,
          link: `/companies/${value.id}`,
          avatar: value.avatar_url,
          showImage: true,
          id: value.id?.toString(),
        };
      },
    },
    [RelatedToType.Note]: {
      map: (value: Note & RelatedToTypeEntity): SearchResultItem => {
        return {
          title: value.display_title,
          created_at: value.created_at,
          relatedToType: value.relatedToType,
          showImage: false,
          link: this.urlForNote(value),
          id: value.id?.toString(),
        };
      },
    },
  };

  @Select(FieldDefsState.getFieldDefs) private fieldDefs$: Observable<FieldDef[]>;

  @Input() debounce: number;
  @Input() account: Account;
  @Input() minLength: number;
  @Input() set appearance(value: 'basic' | 'bar') {
    // If the value is the same, return...
    if (this._appearance === value) {
      return;
    }

    // Make sure the search is closed, before
    // changing the appearance to prevent issues
    this.close();

    let appearanceClassName;

    // Remove the previous appearance class
    appearanceClassName = 'search-appearance-' + this.appearance;
    this.renderer2.removeClass(this.elementRef.nativeElement, appearanceClassName);

    // Store the appearance
    this._appearance = value;

    // Add the new appearance class
    appearanceClassName = 'search-appearance-' + this.appearance;
    this.renderer2.addClass(this.elementRef.nativeElement, appearanceClassName);
  }
  @Output() search: EventEmitter<any>;

  @ViewChild('searchInput') set searchInput(value: MatFormField) {
    // Return if the appearance is basic, since we don't want
    // basic search to be focused as soon as the page loads
    if (this.appearance === 'basic') {
      return;
    }

    // If the value exists, it means that the search input
    // is now in the DOM and we can focus on the input..
    if (value) {
      // Give Angular time to complete the change detection cycle
      setTimeout(() => {
        // Focus to the input element
        value._inputContainerRef.nativeElement.children[0].focus();
      });
    }
  }

  get appearance(): 'basic' | 'bar' {
    return this._appearance;
  }

  set opened(value: boolean) {
    // If the value is the same, return...
    if (this._opened === value) {
      return;
    }

    // Store the opened status
    this._opened = value;

    // If opened...
    if (value) {
      // Add opened class
      this.renderer2.addClass(this.elementRef.nativeElement, 'search-opened');
    } else {
      // Remove opened class
      this.renderer2.removeClass(this.elementRef.nativeElement, 'search-opened');
    }
  }

  get opened(): boolean {
    return this._opened;
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer2: Renderer2,
    private readonly router: Router,
    private readonly store: Store,
    private readonly globalSearchService: GlobalSearchService,
  ) {
    super();
    this.appearance = 'basic';
    this.debounce = this.debounce || 300;
    this.minLength = this.minLength || 2;
    this.opened = false;
    this.results = null;
    this.searchControl = new UntypedFormControl();
  }

  ngOnInit(): void {
    if (!this.store.selectSnapshot(FieldDefsState.getFieldDefs)?.length) {
      this.store.dispatch(Field.Get);
    }

    this.fieldDefs$.pipe(untilComponentDestroyed(this)).subscribe((fieldDefs) => (this.fieldDefs = fieldDefs));

    this.searchControl.valueChanges
      .pipe(
        untilComponentDestroyed(this),
        debounceTime(this.debounce),
        filter((query: string) => {
          if (!query?.length) {
            this.results = null;
          }

          return query?.length >= 2;
        }),
        tap(() => (this.ui = { isLoading: true })),
        switchMap((query) =>
          this.globalSearchService.search({
            query,
            accountId: Number(this.account.id),
            types: Object.keys(this.searchableTypes) as RelatedToType[],
          }),
        ),
        map((results) =>
          Object.keys(results)
            .reduce((prev, curr) => [...prev, ...results[curr].map((item) => ({ ...item, relatedToType: curr }))], [])
            .map((item) => ({
              ...this.searchableTypes[item.relatedToType as RelatedToType].map(item),
              highlights: this.parseHightlights(item.search_highlights, item.relatedToType),
            })),
        ),
      )
      .subscribe((results) => {
        this.results = results || [];
        this.ui = { isLoading: false };
      });
  }

  selectOption(): void {
    this.close();
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.appearance === 'bar' && event.key === 'Escape') {
      this.close();
    }
  }

  open(): void {
    if (this.opened) {
      return;
    }

    this.opened = true;
  }

  close(): void {
    if (!this.opened) {
      return;
    }

    this.searchControl.setValue('');
    this.opened = false;
    this.results = null;
  }

  private parseHightlights(value: Dictionary<any>, relatedToType: RelatedToType): SearchResultHighlight[] {
    if (!value) {
      return [];
    }

    return Object.keys(value).reduce((prev, fieldName) => {
      const fieldDef = this.fieldDefs.find(
        ({ name, related_to_type }) => name === fieldName && related_to_type == relatedToType,
      );
      const label = fieldDef?.label ?? fieldName;

      return [...prev, { label, content: value[fieldName].replace(/<.*?>/g, '') }];
    }, []);
  }
}
