import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProjectTemplate } from './projects.types';

@Injectable({ providedIn: 'root' })
export class ProjectTemplateService {
  constructor(private readonly httpClient: HttpClient) {}

  create<T>(projectTemplate: ProjectTemplate<T>): Observable<ProjectTemplate<T>> {
    return this.httpClient
      .post<{ project_template: ProjectTemplate<T> }>(`project_templates`, {
        project_template: projectTemplate,
      })
      .pipe(map(({ project_template }) => project_template));
  }

  update<T>(projectTemplate: ProjectTemplate<T>): Observable<ProjectTemplate<T>> {
    return this.httpClient
      .put<{ project_template: ProjectTemplate<T> }>(`project_templates/${projectTemplate.id}`, {
        project_template: projectTemplate,
      })
      .pipe(map(({ project_template }) => project_template));
  }
}
