import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Account, AccountColor } from 'app/core/models';
import { AccountActions } from './actions';
import { FeatureFlag } from '../../core/enums';
import { AccountService } from '../../core/services';
import { tap } from 'rxjs';

export interface AccountStateModel {
  account: Account;
}

@State<AccountStateModel>({
  name: new StateToken<AccountStateModel>('accountState'),
  defaults: { account: null },
})
@Injectable()
export class AccountState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static getAccount(state: AccountStateModel): Account {
    return state?.account;
  }

  @Selector([AccountState.getAccount])
  static getAccountId(state: AccountStateModel, account: Account): string {
    return state?.account.id;
  }

  @Selector([AccountState.getAccount])
  static getFeatures(state: AccountStateModel, account: Account): string[] | undefined {
    return account?.features;
  }

  @Selector([AccountState.getAccount])
  static selectAccountColors(state: AccountStateModel, account: Account): AccountColor[] {
    return account?.colors;
  }

  @Selector([AccountState.selectAccountColors])
  static selectAccountColorsWithNone(state: AccountStateModel, colors: AccountColor[]): AccountColor[] {
    // adds to the color list a default system color, often used in deals panels
    return [
      { id: 0, name: null, label: 'Default Color', hex_value: '#F1F5F9', color_type: 'none', _destroy: false },
      ...colors,
    ];
  }

  @Selector([AccountState.selectAccountColors])
  static selectAccountColorHexMap(state: AccountStateModel, colors: AccountColor[]): Record<string, string> {
    if (!colors) return {};

    return colors.reduce((acc, color) => {
      acc[color.name] = color.hex_value;
      return acc;
    }, {});
  }

  static isFeatureEnabled(feature: FeatureFlag) {
    return createSelector([AccountState.getFeatures], (features: string[] | undefined): boolean => {
      return features && features.includes(feature);
    });
  }

  @Action(AccountActions.SetAccount)
  setAccount(context: StateContext<AccountStateModel>, { account }: AccountStateModel) {
    const state = context.getState();
    context.setState({ ...state, account });
  }

  @Action(AccountActions.UpdateAccount)
  updateAccount(context: StateContext<AccountStateModel>, { settings }: any) {
    return this.accountService.updateAccount(settings).pipe(
      tap((account: Account) => {
        context.setState({ ...context.getState(), account });
      }),
    );
  }

  @Action(AccountActions.UploadAccountImage)
  addAccount(context: StateContext<AccountStateModel>, { file }: any) {
    return this.accountService.uploadAccountImage(file).pipe(
      tap((account: Account) => {
        context.setState({ ...context.getState(), account });
      }),
    );
  }

  @Action(AccountActions.RemoveAccountImage)
  removeAccountImage(context: StateContext<AccountStateModel>) {
    return this.accountService.removeAccountImage().pipe(
      tap((account: Account) => {
        context.setState({ ...context.getState(), account });
      }),
    );
  }
}
