import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  private ignorePath = ['/integrations/oauth_connect/authenticate', '/integrations/saml/authenticate'];
  private store = inject(Store);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreApiPrefix = this.ignorePath.filter((path) => request.url.includes(path)).length > 0;
    const basePath = request.url.split('?')[0];

    if (
      !ignoreApiPrefix &&
      !basePath.includes('/api/') &&
      !basePath.includes('assets') &&
      !basePath.includes('version.json')
    ) {
      let url = `${environment.baseUrl}/${environment.apiVersion}/${request.url}`;

      url = this.addRequiredParams(url);

      const apiReq = request.clone({ url });
      return next.handle(apiReq);
    } else {
      let newReq = request.clone();
      return next.handle(newReq);
    }
  }

  private addRequiredParams(url: string): string {
    if (url.match(/account_id=\d+/g)) {
      return url;
    }

    const accountId = this.store.selectSnapshot(AccountState.getAccount)?.id;

    if (!accountId) {
      return url;
    }

    url += (url.indexOf('?') > -1 ? '&' : '?') + `account_id=${accountId}`;

    return url;
  }
}
