import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';
import { Store } from '@ngxs/store';
import { UserState } from '@state/user/state';
import { AccountState } from '@state/account/state';
import { TsEmptyStateComponent } from 'tsui/lib/ts-empty-state/ts-empty-state.component';
import { RollbarErrorHandler } from '@core/services';

@Component({
  selector: 'not-found',
  standalone: true,
  imports: [TsEmptyStateComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  providers: [RollbarErrorHandler],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  readonly store = inject(Store);
  readonly rollbar = inject(RollbarErrorHandler);
  readonly router = inject(Router);
  readonly destroyRef = inject(DestroyRef);

  private history: string[] = [];

  readonly goBackButtonConfig: { label: string; actionFn: () => void } = {
    label: 'Go back',
    actionFn: () => {
      this.goBack();
    },
  };

  get userId(): number {
    return this.store.selectSnapshot(UserState.getUser)?.id;
  }

  get accountSubdomain(): string {
    return this.store.selectSnapshot(AccountState.getAccount)?.subdomain;
  }

  getHistory(): string[] {
    return [...this.history];
  }

  goBack(): void {
    if (this.history.length > 1) {
      this.history.pop();
      const previousUrl = this.history.pop();
      if (previousUrl) {
        this.router.navigateByUrl(previousUrl);
      }
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.history.push(event.urlAfterRedirects);
      });

    this.rollbar.sendWarning(
      `Warning: User ${this.userId} attempted to access a non-existent route on ${this.accountSubdomain}`,
    );
  }
}
