import { Approval } from '@shared/modules/approvals/approvals.types';
import { TaskModuleRelatedToType, Task, TaskType, Todo, TaskList } from '../tasks.types';
import { User } from '@core/models';
import { Contact } from 'app/modules/contacts/contacts.types';

export namespace TaskActions {
  export class SetRelatedTo {
    static readonly type = '[Task] Set Related to Type and Related to Type Id';

    constructor(
      public relatedToType: TaskModuleRelatedToType,
      public relatedToTypeId: number,
    ) {}
  }

  export class GetTaskDetail {
    static readonly type = '[Task] Get Task Detail By Id';

    constructor(
      public taskListId: number,
      public taskId: number,
    ) {}
  }

  export class DuplicateTask {
    static readonly type = '[Task] Duplicate Task';

    constructor(
      public taskListId: number,
      public task: Task,
      public templateId: number,
    ) {}
  }

  export class UpdateTask {
    static readonly type = '[Task] Update Task By Id';

    constructor(
      public task: Task,
      public templateId?: number,
    ) {}
  }

  export class CreateTask {
    static readonly type = '[Task] Create Task';

    constructor(public model: { taskType: TaskType; parentId?: number; rank?: number }) {}
  }

  export class CreateTodo {
    static readonly type = '[Task] Create Todo';

    constructor() {}
  }

  export class DeleteTodo {
    static readonly type = '[Task] Delete Todo';

    constructor(
      public task: Task,
      public todo: Todo,
    ) {}
  }
  export class AddApprover {
    static readonly type = '[Task] Add approver';

    constructor(
      public task: Task,
      public approval: Approval,
    ) {}
  }

  export class RemoveApprover {
    static readonly type = '[Task] Remove approver';

    constructor(
      public task: Task,
      public approval: Approval,
    ) {}
  }

  export class AddFollower {
    static readonly type = '[Task] Add User as Follower';

    constructor(
      public task: Task,
      public user: User,
    ) {}
  }

  export class RemoveFollower {
    static readonly type = '[Task] Remover User from follower list';

    constructor(
      public task: Task,
      public user: User,
    ) {}
  }

  export class AddContact {
    static readonly type = '[Task] Add Contact';

    constructor(
      public task: Task,
      public contact: Partial<Contact>,
    ) {}
  }

  export class RemoveContact {
    static readonly type = '[Task] Remove Contact list';

    constructor(
      public task: Task,
      public contact: Partial<Contact>,
    ) {}
  }

  export class CreateSubTask {
    static readonly type = '[Task] Create Sub Task';

    constructor() {}
  }

  export class DeleteTask {
    static readonly type = '[Task] Delete';

    constructor(
      public taskListId: number,
      public taskId: number,
      public templateId?: number,
    ) {}
  }

  export class GetTaskLists {
    static readonly type = '[Task] Get Tasks List';

    constructor() {}
  }

  export class SetTaskLists {
    static readonly type = '[Task] Set Task Lists';

    constructor(public taskLists: TaskList[]) {}
  }

  export class GetTaskListById {
    static readonly type = '[Task] Get Task List By Id';

    constructor(
      public taskListId: number,
      public templateId: number,
      public includeTasks: boolean,
    ) {}
  }

  export class SetTaskList {
    static readonly type = '[Task] Update State with Task List';

    constructor(public taskList: TaskList) {}
  }

  export class UpdateTaskList {
    static readonly type = '[Task] Update Task List';

    constructor(public taskList: TaskList) {}
  }

  export class RecalculateTaskListDates {
    static readonly type = '[Task] Recalculate Task List Dates';

    constructor(public taskListId: number) {}
  }

  export class ClearTaskList {
    static readonly type = '[Task] Clear Task list state';

    constructor() {}
  }

  export class GetTasks {
    static readonly type = '[Task] Get Tasks By Tas List Id';

    constructor(
      public taskListId: number,
      public filters: any,
    ) {}
  }

  export class FilterTasks {
    static readonly type = '[Task] Filter Tasks';

    constructor(
      public queryFilters: {
        fjson: string;
        sort?: { field: string; order: string };
        q?: string;
        filterOptions?: { include_keydates: boolean };
        select?: string[];
      },
    ) {}
  }

  export class ReorderTasks {
    static readonly type = '[Task] Reorder Tasks';

    constructor(
      public taskListId: number,
      public tasks: Task[],
      public templateId?: number,
    ) {}
  }

  export class ClearFilter {
    static readonly type = '[Task] Clear searched ids';
    constructor() {}
  }

  export class ClearCurrentTask {
    static readonly type = '[Task] Clear Current Task';
  }
}
