import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FieldDef } from 'app/core/models';
import { FileFormat, RelatedToType } from 'app/shared/enums';
import { ExportOptions } from 'app/shared/models/export-options';
import {
  GraphqlConnection,
  GraphqlConnectionMap,
  GraphqlParams,
  GraphqlResult,
  GraphqlResultInfo,
  GraphqlResultPageInfo,
} from 'app/shared/models/graphql';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { map, Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export default class GraphqlService extends BaseService {
  static readonly graphQlTypesForNestedFields = Object.freeze({
    text: 'cf_text',
    multi_select: 'cf_select_multi',
    select: 'cf_select',
    number: 'cf_number',
    year: 'cf_year',
    percent: 'cf_percent',
    decimal: 'cf_decimal',
    money: 'cf_money',
    checkbox: 'cf_checkbox',
    datetime: 'cf_datetime',
    date: 'cf_date',
  });

  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  query<T>(accountId: number, params: GraphqlParams, exportOptions?: ExportOptions): Observable<GraphqlResultInfo<T>> {
    const {
      connection,
      fields,
      fieldDefs,
      filter,
      group,
      options,
      startDate,
      endDate,
      cadence,
      groupBy,
      summarizeMethod,
      excludeSubtotals,
      relatedToType,
      format,
      processNodeFields,
      normalizeMethod,
    } = params;
    if (fieldDefs) {
      params.fields = fieldDefs.map((field) => field?.name).filter((name) => !!name);
    }

    const page = filter?.pagination?.page ?? 1;
    const perPage = filter?.pagination?.size ?? 25;
    const args = {
      filter: { ...(params.filter.filterDictionary ?? {}), q: params.filter?.query ?? '' },
      page,
      perPage,
    };

    let connectionName = options?.keepConnectionCase ? connection : connection.toLowerCase();
    let node = fields.reduce((acc, fieldName) => {
      if (this.isNestedField(fieldDefs, fieldName)) {
        const [parentField, childField] = fieldName.split('.');

        const currentFieldDef = fieldDefs.find((fieldDef) => fieldDef?.name === fieldName);
        const parentFieldDef = fieldDefs.find((fieldDef) => fieldDef?.name === parentField);
        const isParentStandardObject = Object.values(RelatedToType).includes(
          parentFieldDef?.meta?.record_type as RelatedToType,
        );

        if (currentFieldDef) {
          const childFieldKey = this.addGraphQlTypeToNestedField(childField, currentFieldDef, isParentStandardObject);

          acc[parentField] = {
            __aliasFor: this.getLinkedFieldAlias(parentField, fieldDefs),
            ...(acc[parentField] || {}),
            [childFieldKey]: true,
            ...this.getFieldsToCreateLinks(currentFieldDef, isParentStandardObject),
          };
        }
        return acc;
      }
      return {
        ...acc,
        [fieldName]: true,
      };
    }, {} as any);

    if (normalizeMethod) {
      node = { ...node, ...normalizeMethod(fields) };
    }

    if (GraphqlConnectionMap[connection]) {
      connectionName = GraphqlConnectionMap[connection];

      if (
        ![GraphqlConnection.timeSeries as string, GraphqlConnection.projectsKanban as string].includes(connectionName)
      ) {
        node = processNodeFields ? this.normalizeFields([...fields, 'template_id']) : node;
      } else {
        const groupByFields = groupBy?.map(({ field }) => field) || [];
        node = {
          id: true,
          title: true,
          ...node,
          ...groupByFields.reduce((p, c) => ({ ...p, [c]: true }), {} as any),
        };

        if (!exportOptions?.format && format !== 'time_series_json') {
          this.handleComplexTypes(
            node,
            groupByFields.map((field) => field.replace('_id', '')),
          );
        }
      }
    }

    if (filter?.fjson) {
      args.filter['fjson'] = filter?.fjson;
    }

    if (filter?.sort) {
      args['msort'] = filter.sort;
    }

    if (filter?.location) {
      args.filter['location'] = filter.location;
    }

    if (filter?.pipeline_id) {
      args.filter['pipelineIdEq'] = filter.pipeline_id;
    }

    if (group) {
      args['group'] = group;
    }

    if (startDate) {
      args['startDate'] = startDate;
    }

    if (endDate) {
      args['endDate'] = endDate;
    }

    if (cadence) {
      args['cadence'] = cadence;
    }

    if (groupBy) {
      args['groupBy'] = groupBy;
    }

    if (summarizeMethod) {
      args['summarizeMethod'] = summarizeMethod;
    }

    if (relatedToType) {
      args['relatedToType'] = relatedToType;
    }

    if (excludeSubtotals) {
      args['excludeSubtotals'] = excludeSubtotals;
    }

    node = this.addPermissionsRequestFields(connectionName, node);

    const query = {
      query: {
        [connectionName]: {
          __args: args,
          pageInfo: {
            hasNextPage: true,
            hasPreviousPage: true,
            endCursor: true,
            startCursor: true,
            totalCount: true,
            filteredCount: true,
          },
          edges: {
            cursor: true,
            node,
          },
        },
      },
    };

    if (options?.bucketsCount) {
      query.query[connectionName]['bucketsCount'] = true;
    }

    const gqlQuery = jsonToGraphQLQuery(query, { pretty: true });

    let queryStringObj = new HttpParams({
      fromObject: {
        ...(params.filter?.queryObject ?? {}),
        format: (exportOptions?.format || format) ?? 'null',
        ...(exportOptions?.savedViewId ? { saved_view_id: exportOptions.savedViewId } : {}),
        ...(exportOptions?.reportLayoutId ? { report_layout_id: exportOptions.reportLayoutId } : {}),
      },
    });

    if (exportOptions?.excludeFields?.length > 0) {
      queryStringObj = queryStringObj.set('exclude_fields_from_pdf', exportOptions.excludeFields.join(','));
    }

    const queryString = queryStringObj.toString();
    return this.httpClient
      .post<{ data: GraphqlResult<T> | any; headers?: string[]; pageInfo: GraphqlResultPageInfo }>(
        `graphql?${queryString}`,
        {
          query: gqlQuery,
        },
      )
      .pipe(
        map((response) => {
          const { data, headers, pageInfo } = response;

          const isExportResponse =
            exportOptions && [FileFormat.CSV, FileFormat.XLSX, FileFormat.PDF].includes(exportOptions.format);

          if (!isExportResponse) {
            if (format === 'time_series_json') {
              return { timeSeries: { data, headers }, pageInfo } as GraphqlResultInfo<T>;
            }

            if (format === 'json') {
              return { timeSeries: { data, headers }, pageInfo } as GraphqlResultInfo<T>;
            }
          }

          if (isExportResponse) {
            return data;
          }

          const graphqlResult = data[connectionName] as GraphqlResultInfo<T>;

          if (options?.bucketsCount && typeof graphqlResult.bucketsCount === 'string') {
            graphqlResult.bucketsCount = JSON.parse(graphqlResult.bucketsCount);
          }

          return {
            ...graphqlResult,
            pageInfo: { ...(graphqlResult.pageInfo || pageInfo || {}), currentPage: page, pageSize: perPage },
          } as GraphqlResultInfo<T>;
        }),
      );
  }

  private addPermissionsRequestFields(connectionName: string, node: any): any {
    const permissionableTypes = [
      GraphqlConnection.projects,
      GraphqlConnection.properties,
      GraphqlConnection.propertiesSqlBased,
      GraphqlConnection.projectsKanban,
    ] as string[];

    if (permissionableTypes.includes(connectionName)) {
      node['permissions'] = { is_deletable: true, is_editable: true, is_viewable: true };
    }

    return node;
  }

  private addGraphQlTypeToNestedField(childField: string, currentFieldDef: FieldDef, isParentStandardObject: boolean) {
    const processedFieldType = this.processFieldType(currentFieldDef);

    // Standard Objects add type annotation only to custom fields
    if (isParentStandardObject && currentFieldDef?.meta?.is_custom) {
      return `${childField}: ${GraphqlService.graphQlTypesForNestedFields[processedFieldType] ?? 'cf_text'}`;
    }
    if (isParentStandardObject) return childField;

    // Custom Object add type annotation to all fields
    return `${childField}: ${GraphqlService.graphQlTypesForNestedFields[processedFieldType] ?? 'cf_text'}`;
  }

  private isNestedField(fieldDefs: FieldDef[], c: string): boolean {
    return fieldDefs?.length > 0 && c.split('.').length > 1;
  }

  private getFieldsToCreateLinks(field: FieldDef, isParentStandardObject = true): { [key: string]: boolean } {
    const injectGraphQlTypeForIds = (field: string) => {
      return isParentStandardObject ? field : `${field}: ${GraphqlService.graphQlTypesForNestedFields.number}`;
    };

    let defaultFields = {
      [injectGraphQlTypeForIds('id')]: true,
    };

    if (field.name.startsWith('cf_linked_co_')) {
      defaultFields[injectGraphQlTypeForIds('custom_object_id')] = true;
    }

    if (field.name.startsWith('cf_linked_')) {
      if (field.name.includes('project')) {
        defaultFields[injectGraphQlTypeForIds('pipeline_id')] = true;
      }
    }

    const recordType = field.related_to_type || field?.meta?.record_type;

    if (recordType.startsWith('co_')) {
      defaultFields[injectGraphQlTypeForIds('custom_object_id')] = true;
    } else if (recordType === 'Project') {
      defaultFields[injectGraphQlTypeForIds('pipeline_id')] = true;
    }

    return defaultFields;
  }

  getLinkedFieldAlias(field: string, allFields: FieldDef[]): string {
    const currentField = allFields.find((f) => f.name === field);
    const recordType = currentField?.meta?.record_type.startsWith('co_')
      ? 'custom_object'
      : currentField?.meta?.record_type.toLowerCase();

    let alias = `nested_${recordType}`;
    if (currentField?.meta?.multi_select) return `${alias}_multi`;

    return alias;
  }

  private normalizeFields(fields: string[]): any {
    const requestFields = fields.reduce((p, c) => ({ ...p, [c]: true }), {});

    if (!fields.includes('id')) {
      requestFields['id'] = true;
    }
    if (!fields.includes('created_at')) {
      requestFields['created_at'] = true;
    }
    if (!fields.includes('updated_at')) {
      requestFields['updated_at'] = true;
    }
    if (!fields.includes('address_string')) {
      requestFields['address_string'] = true;
    }
    if (!fields.includes('featured_image_url')) {
      requestFields['featured_image_url'] = true;
    }
    if (!fields.includes('title')) {
      requestFields['title'] = true;
    }
    if (!fields.includes('address_string')) {
      requestFields['address_string'] = true;
    }
    if (!fields.includes('project_stage_id')) {
      requestFields['project_stage_id'] = true;
    }

    if (fields.includes('address')) {
      requestFields['address'] = {
        latitude: true,
        longitude: true,
      };
    }

    if (fields.includes('children')) {
      requestFields['children'] = {};
      for (let field of fields) {
        if (field !== 'children') {
          requestFields['children'][field] = true;
        }
      }
      requestFields['children']['id'] = true;
      requestFields['children']['is_child'] = true;
      requestFields['children']['project_stage_id'] = true;
    }

    this.handleComplexTypes(requestFields);

    requestFields['is_child'] = true;
    requestFields['is_parent'] = true;
    requestFields['root_folder_resource_id'] = true;

    return requestFields;
  }

  private handleComplexTypes(requestFields: any, allowed: string[] = []): any {
    if (!allowed.length || allowed.includes('project_stage')) {
      requestFields['project_stage'] = {
        id: true,
        name: true,
        color: true,
      };
    }

    if (!allowed.length || allowed.includes('investment_type')) {
      requestFields['investment_type'] = {
        id: true,
        name: true,
      };
    }

    if (!allowed.length || allowed.includes('project_type')) {
      requestFields['project_type'] = {
        id: true,
        name: true,
      };
    }

    if (!allowed.length || allowed.includes('property_type')) {
      requestFields['property_type'] = {
        id: true,
        name: true,
        color: true,
      };
    }

    if (!allowed.length || allowed.includes('vehicle')) {
      requestFields['vehicle'] = {
        id: true,
        name: true,
      };
    }

    if (!allowed.length || allowed.includes('team')) {
      requestFields['team'] = {
        id: true,
        name: true,
      };
    }

    if (!allowed.length || allowed.includes('assigned_user')) {
      requestFields['assigned_user'] = {
        id: true,
        name: true,
      };
    }

    if (!allowed.length || allowed.includes('project_phase')) {
      requestFields['project_phase'] = {
        id: true,
        name: true,
      };
    }
  }

  private processFieldType(currentFieldDef: FieldDef): string {
    if (currentFieldDef?.type === 'select') {
      return currentFieldDef?.meta?.multi_select ? 'multi_select' : 'select';
    }

    return currentFieldDef?.type;
  }
}
